<template>
  <div id="tooth_styles">
    <mainMenu></mainMenu>
    <div class="headerPreview">
      <div class="wrapper">
        <div class="sectionInfo">
          <h1 class="sectionTitle">
            3D библиотека фасонов <br />
            зубов
          </h1>
          <h2 class="sectionDescription">Pogarskiy Design library</h2>
          <button
            type="button"
            class="btnAbout"
            @click="isAbout = !isAbout"
            v-html="!isAbout ? 'Читать о библиотеке' : '< Вернуться'"
          ></button>
          <button type="button" class="btnShareLink" v-if="!dynamicSrcBar" @click="dynamicSrcBar = !dynamicSrcBar">
            Предоставить ссылку для просмтра
          </button>
          <div class="dynamicSrcBar" v-if="dynamicSrcBar">
            <input type="url" name="URL" value="https://www.dentabula.com/tooth_styles/preview" />
            <button type="button" class="btnModalWindow" @click="modalWindow = !modalWindow">Просмотреть</button>
          </div>
        </div>
      </div>
    </div>
    <imgPreviewer
      v-if="modalWindow"
      @close="modalWindow = false"
      :imgUrl="require('./../../assets/images/tooth_styles_info/all_styles.jpg')"
    ></imgPreviewer>
    <div class="tooth_styles_view" v-if="!isAbout">
      <div class="tooth_styles_view-goBack">
        <router-link :to="{ name: 'library3d' }">
          <icon name="chevron-left" scale="1.8" class="mr-50"></icon>
          <span>Вернуться к 3D библиотекам</span>
        </router-link>
      </div>
      <div class="tooth_styles_view_wrapper">
        <router-link
          :to="{ name: 'toothStylesItem', params: { styleId: style._id } }"
          v-for="style in stylesList"
          :key="style.id"
          class="toothStyle"
        >
          <img :src="style.logo" alt="" />
        </router-link>
      </div>
      <infinite-loading @infinite="getStylesList">
        <span slot="no-more">
          <span v-if="!isLoggedIn && !isPremium">
            Для получения возможности скачивания всех библиотек в формате psd, pptx, stl и eoff Вам необходимо <br />
            <router-link :to="{ name: 'signin' }">зарегистрироваться</router-link> и приобрести премиум аккаунт
          </span>
          <span v-if="isLoggedIn && !isPremium">
            Для скачивания всех библиотек в формате psd, pptx, stl и eoff Вам необходимо приобрести
            <router-link :to="{ name: 'profile' }">премиум аккаунт</router-link>
          </span>
        </span>
      </infinite-loading>
    </div>
    <div id="tooth_styles_about" v-if="isAbout">
      <section id="tooth_styles_section_1">
        <div class="wrapper">
          <div class="modelsDeisgn">
            <img src="./../../assets/images/2d_tooth_design.png" alt="" class="modelsDeisgn-img" />
            <p class="modelsDeisgn-about">2D шаблоны для дизайна улыбки в PPTX и PSD</p>
          </div>
          <div class="modelsDeisgn">
            <img src="./../../assets/images/3d_tooth_design.png" alt="" class="modelsDeisgn-img" />
            <p class="modelsDeisgn-about">3D фасоны зубов в форматах STL и EOFF для EXOCAD</p>
          </div>
        </div>
      </section>
      <section id="tooth_styles_section_2">
        <div class="wrapper">
          <p class="smileConstruct-title">
            Применение одной библиотеки в виде 2D и 3D шаблонов позволяют спроектировать дизайн цифровой улыбки, мокап,
            а также реставрацию или протез используя одену форму зубов
          </p>
          <div class="smileConstruct-preview">
            <div class="smileConstruct-type">
              <img
                src="./../../assets/images/tooth_styles_info/Цифровая улыбка.png"
                alt=" "
                class="smileConstruct-img"
              />
              <p class="smileConstruct-about text-purple">Проектирование цифровой улыбки</p>
            </div>
            <div class="smileConstruct-type">
              <img
                src="./../../assets/images/tooth_styles_info/Проектирование мокапа.png"
                alt=" "
                class="smileConstruct-img"
              />
              <p class="smileConstruct-about text-green">Проектирование мокапа</p>
            </div>
            <div class="smileConstruct-type">
              <img
                src="./../../assets/images/tooth_styles_info/Изготовление конструкций.png"
                alt=" "
                class="smileConstruct-img"
              />
              <p class="smileConstruct-about text-purple">Изготовление конструкции</p>
            </div>
          </div>
        </div>
      </section>
      <section id="tooth_styles_section_3">
        <div class="wrapper">
          <p class="toothFashion3D-about">
            <span class="toothFashion3D-about-accent">3D фасоны зубов</span>
            были разработаны для цифрового моделирования реставрационных протезных конструкций. <br />
            STL формат позволяет использовать фасоны для большого спектра 3D программ, а также конвертировать его в
            форматы других программ. <br />
            Фасоны зубов в формате EOFF (для EXOCAD) разработаны специально для использования в специализированной
            программе EXOCAD. <br />
            Фасоны выполнены в полной симметрии и соответствуют 2D шаблонам данной библиотеки по форме, что позволит
            воссоздать <br />
            планирование улыбки в 3D формате.
          </p>
          <p class="toothFashion3D-title">
            <span class="toothFashion3D-title-accent">3D</span>
            библиотека содержит 7 разных групп жевательных зубов верхней и нижней челюстей
          </p>
          <div class="toothFashion3D-demo">
            <div class="toothFashion3D-demo-container">
              <img
                src="./../../assets/images/tooth_styles_info/tooth_group_1.png"
                alt=""
                class="toothFashion3D-demo-container-img"
              />
            </div>
            <div class="toothFashion3D-demo-container">
              <img
                src="./../../assets/images/tooth_styles_info/tooth_group_2.png"
                alt=""
                class="toothFashion3D-demo-container-img"
              />
            </div>
            <div class="toothFashion3D-demo-container">
              <img
                src="./../../assets/images/tooth_styles_info/tooth_group_3.png"
                alt=""
                class="toothFashion3D-demo-container-img"
              />
            </div>
            <div class="toothFashion3D-demo-container">
              <img
                src="./../../assets/images/tooth_styles_info/tooth_group_4.png"
                alt=""
                class="toothFashion3D-demo-container-img"
              />
            </div>
            <div class="toothFashion3D-demo-container">
              <img
                src="./../../assets/images/tooth_styles_info/tooth_group_5.png"
                alt=""
                class="toothFashion3D-demo-container-img"
              />
            </div>
            <div class="toothFashion3D-demo-container">
              <img
                src="./../../assets/images/tooth_styles_info/tooth_group_6.png"
                alt=""
                class="toothFashion3D-demo-container-img"
              />
            </div>
            <div class="toothFashion3D-demo-container">
              <img
                src="./../../assets/images/tooth_styles_info/tooth_group_7.png"
                alt=""
                class="toothFashion3D-demo-container-img"
              />
            </div>
          </div>
        </div>
      </section>
      <section id="tooth_styles_section_4">
        <div class="wrapper">
          <p class="toothFashion2D-about-top">
            3D фасоны зубов в формате .eoff адаптированы для использования в последних версиях программы EXOCAD и
            позволяют отображать контуры в модуле SmileDesign
          </p>
          <img src="./../../assets/images/tooth_styles_info/smile_design.png" class="toothFashion2D-about-img" alt="" />
          <p class="toothFashion2D-about">
            <span class="toothFashion2D-about-accent">2D фасоны зубов</span>
            разработаны для создания цифрового дизайна улыбки. Вид данного планирования позволяет определить вид будущих
            зубов, их форму, размер и пропорцию относительно друг друга и лица пациента. Цифровой дизайн улыбки не
            сложная процедура, которая не требует использования специальных программ, но при этом значимость ее
            неоценима. Данная методика применяется, как при планировании протезирования, так и реставраций и даже
            ортодонтического лечения.
          </p>
          <p class="toothFashion2D-title">
            <span class="toothFashion2D-title-accent">2D</span>
            фасоны зубов представлены в виде растровых и векторных изображений, позволяющих создавать контуры и форму
            верхних фронтальных 8-и зубов. Библиотека представляет собой набор файлов в формате PPTX (PowerPoint для
            Windows, Keynote для MacOS) или PSD (для Photoshop)
          </p>
          <div class="toothFashion2D-content">
            <div class="toothFashion2D-content-container">
              <img
                src="./../../assets/images/tooth_styles_info/Fashion_2D_Img_1.png"
                alt=""
                class="toothFashion2D-content-container-img"
              />
            </div>
            <div class="toothFashion2D-content-container">
              <img
                src="./../../assets/images/tooth_styles_info/Fashion_2D_Img_2.png"
                alt=""
                class="toothFashion2D-content-container-img"
              />
            </div>
            <div class="toothFashion2D-content-container">
              <img
                src="./../../assets/images/tooth_styles_info/Fashion_2D_Img_3.png"
                alt=""
                class="toothFashion2D-content-container-img"
              />
            </div>
            <div class="toothFashion2D-content-container">
              <img
                src="./../../assets/images/tooth_styles_info/Fashion_2D_Img_4.png"
                alt=""
                class="toothFashion2D-content-container-img"
              />
            </div>
          </div>
        </div>
      </section>
      <section id="tooth_styles_section_5">
        <div class="wrapper">
          <p class="starterPack-title">
            Для работы над дизайном улыбки необходим базовый набор инструментов:
            <span class="starterPack-title-accent">Start template</span>
          </p>
          <div class="starterPack-container">
            <a
              v-if="fileDownload.startTemlatePPTX"
              :href="fileDownload.startTemlatePPTX"
              class="starterPack-container-btn btn-green"
              :download="fileDownload.startTemlatePPTX"
            >
              <img src="./../../assets/images/downloadArrowDown.png" alt="" class="starterPack-container-btn-arrow" />
              <span class="starterPack-container-btn-text">Скачать<br />Start template в PPTX</span>
            </a>
            <router-link
              :to="{ name: isLoggedIn ? 'profile' : 'signin' }"
              v-if="!fileDownload.startTemlatePPTX"
              class="starterPack-container-btn btn-green"
            >
              <img src="./../../assets/images/downloadArrowDown.png" alt="" class="starterPack-container-btn-arrow" />
              <span class="starterPack-container-btn-text"
                >Для скачивания<br />Start template в PPTX<br />необходим статус Premium</span
              >
            </router-link>

            <a
              v-if="fileDownload.startTemlatePPTXPSD"
              :href="fileDownload.startTemlatePPTXPSD"
              class="starterPack-container-btn btn-purple"
              :download="fileDownload.startTemlatePPTX"
            >
              <img src="./../../assets/images/downloadArrowDown.png" alt="" class="starterPack-container-btn-arrow" />
              <span class="starterPack-container-btn-text">Скачать<br />Start template в PSD</span>
            </a>
            <router-link
              :to="{ name: isLoggedIn ? 'profile' : 'signin' }"
              v-if="!fileDownload.startTemlatePPTXPSD"
              class="starterPack-container-btn btn-purple"
            >
              <img src="./../../assets/images/downloadArrowDown.png" alt="" class="starterPack-container-btn-arrow" />
              <span class="starterPack-container-btn-text"
                >Для скачивания<br />Start template в PSD<br />необходим статус Premium</span
              >
            </router-link>
          </div>
        </div>
      </section>
      <section id="tooth_styles_section_6">
        <div class="wrapper">
          <p class="catBack-title">3D фасоны зубов CatBack1 в формате .eoff (для EXOCAD)</p>
          <p class="catBack-about">
            Разработаны для изготовления коронок и мостовидных протезов из диоксида циркония с учетом минимальной
            вестибулярной редукции. Библиотека адаптирована под использование в специализированной программе EXOCAD.
            Данные фасоны с редукцией созданы с сохранением всех контуров, соответственно их можно использовать в единой
            концепции <span class="catBack-about-accent">Pogarskiy Design Library.</span>
          </p>
          <div class="catBack-steps">
            <div class="catBack-steps-item">
              <img src="./../../assets/images/tooth_styles_info/catback-1.png" alt="" />
            </div>
            <div class="catBack-steps-item">
              <img src="./../../assets/images/tooth_styles_info/catback-2.png" alt="" />
            </div>
            <div class="catBack-steps-item">
              <img src="./../../assets/images/tooth_styles_info/catback-3.png" alt="" />
            </div>
          </div>
          <p class="catBack-about">
            Тип редукции CatBack 1 создавался с учетом последующего нанесения керамического слоя на всю вестибулярную
            поверхность зуба, включая экватор и пришеечную часть. Рекомендовано использовать эмалевые керамические массы
            для нанесения. Толщина керамического слоя может составлять 1 - 0.5 мм. Для получения максимально эстетичного
            результата при выборе блока из диоксида циркония желательно использовать мультислойный циркон с
            транслюцентным режущим краем. Использование данной методики подразумевает раскрашивание цирконевой основы
            (редуцированной поверхности) до, или после синтеризации. Более детальные рекомендации можно изучить при
            помощи нижеуказанной схемы.
          </p>
          <div class="catBack-scheme">
            <div class="catBack-scheme-item">
              <div class="catBack-scheme-model">
                <img src="./../../assets/images/tooth_styles_info/catback_model_1.png" alt="" />
              </div>
              <div class="catBack-scheme-tooth">
                <img src="./../../assets/images/tooth_styles_info/catback__tooth_step-1.png" alt="" />
              </div>
            </div>
            <img src="./../../assets/images/tooth_styles_info/pointer.png" alt="" class="catBack-scheme-pointer" />
            <div class="catBack-scheme-item">
              <div class="catBack-scheme-model">
                <img src="./../../assets/images/tooth_styles_info/catback_model_2.png" alt="" />
              </div>
              <div class="catBack-scheme-tooth">
                <img src="./../../assets/images/tooth_styles_info/catback__tooth_step-2.png" alt="" />
              </div>
            </div>
            <img src="./../../assets/images/tooth_styles_info/pointer.png" alt="" class="catBack-scheme-pointer" />
            <div class="catBack-scheme-item">
              <div class="catBack-scheme-model">
                <img src="./../../assets/images/tooth_styles_info/catback_model_3.png" alt="" />
              </div>
              <div class="catBack-scheme-tooth">
                <img src="./../../assets/images/tooth_styles_info/catback__tooth_step-3.png" alt="" />
              </div>
            </div>
            <img src="./../../assets/images/tooth_styles_info/pointer.png" alt="" class="catBack-scheme-pointer" />
            <div class="catBack-scheme-item">
              <div class="catBack-scheme-model">
                <img src="./../../assets/images/tooth_styles_info/catback_model_4.png" alt="" />
              </div>
              <div class="catBack-scheme-tooth">
                <img src="./../../assets/images/tooth_styles_info/catback__tooth_step-4.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="tooth_styles_section_7">
        <div class="wrapper">
          <div class="demoVideoContent">
            <div class="demoVideoContent-container">
              <p class="demoVideoContent-container-title">Фасоны в .eoff для EXOCAD</p>
              <div class="demoVideoContent-container-content">
                <iframe
                  width="100%"
                  height="auto"
                  src="https://www.youtube.com/embed/mfPV1e0BxLw"
                  allow="autoplay; encrypted-media"
                  allowfullscreen
                  style="border: none"
                ></iframe>
              </div>
            </div>
            <div class="demoVideoContent-container">
              <p class="demoVideoContent-container-title">3D фасоны зубов Catback1 в формате .eoff для EXOCAD</p>
              <div class="demoVideoContent-container-content">
                <iframe
                  width="100%"
                  height="auto"
                  src="https://www.youtube.com/embed/B3sbn1tAS2g"
                  allow="autoplay; encrypted-media"
                  allowfullscreen
                  style="border: none"
                ></iframe>
              </div>
            </div>
            <div class="demoVideoContent-container">
              <p class="demoVideoContent-container-title">Фасоны в .stl</p>
              <div class="demoVideoContent-container-content">
                <iframe
                  width="100%"
                  height="auto"
                  src="https://www.youtube.com/embed/wtINmDmNk6o"
                  allow="autoplay; encrypted-media"
                  allowfullscreen
                  style="border: none"
                ></iframe>
              </div>
            </div>
            <div class="demoVideoContent-container">
              <p class="demoVideoContent-container-title">Фасоны в .pptx</p>
              <div class="demoVideoContent-container-content">
                <iframe
                  width="100%"
                  height="auto"
                  src="https://www.youtube.com/embed/LVVbdNIyIvY"
                  allow="autoplay; encrypted-media"
                  allowfullscreen
                  style="border: none"
                ></iframe>
              </div>
            </div>
            <div class="demoVideoContent-container">
              <p class="demoVideoContent-container-title">Фасоны в .psd</p>
              <div class="demoVideoContent-container-content">
                <iframe
                  width="100%"
                  height="auto"
                  src="https://www.youtube.com/embed/PNNI0kVylUs"
                  allow="autoplay; encrypted-media"
                  allowfullscreen
                  style="border: none"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <mainFooter></mainFooter>
  </div>
</template>

<script>
import mainMenu from "./../mainMenu/mainMenu.vue";
import mainFooter from "./../mainFooter/mainFooter.vue";
import imgPreviewer from "./../imgPreviewer/imgPreviewer.vue";

import InfiniteLoading from "vue-infinite-loading";

export default {
  name: "ToothStyles",
  components: { mainMenu, mainFooter, imgPreviewer, InfiniteLoading },
  props: ["is_previewer"],
  data() {
    return {
      isPremium: false,
      isAbout: false,
      page: 1,
      endpoint: "tooth_styles/",
      endpointTemplate: "tooth_styles_template/",
      stylesList: [],

      fileDownload: {
        startTemlatePPTX: "",
        startTemlatePPTXPSD: "",
      },

      dynamicSrcBar: false,
      modalWindow: false,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    getProfile() {
      this.$http.post(this.profileStatusEndpoint).then((response) => {
        this.isPremium = !!response.data.isPremium;
      });
    },
    getFileDownload() {
      this.$http.get(this.endpointTemplate).then((response) => {
        if (response.data.success) {
          this.fileDownload.startTemlatePPTX = response.data.links.startTemlatePPTX;
          this.fileDownload.startTemlatePPTXPSD = response.data.links.startTemlatePPTXPSD;
        }
      });
    },
    getStylesList($state) {
      var options = {
        params: { page: this.page },
      };
      this.$http.get(this.endpoint, options).then((response) => {
        // console.log(response);
        if (response.data.toothStylesList.length) {
          this.stylesList = this.stylesList.concat(response.data.toothStylesList);
          this.page++;
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
  },
  created() {
    this.getProfile();
    this.getFileDownload();
    this.modalWindow = this.is_previewer ? true : false;
  },
};
</script>

<style lang="sass">
#tooth_styles
	display: flex
	flex-direction: column
	min-height: calc(100vh - 155px)
	& .headerPreview
		display: flex
		min-height: 360px
		align-items: center
		padding: 0 2rem
		background: url('./../../assets/images/tooth_stylesBackground.png')
		background-size: cover
		background-position: center center
		& .sectionInfo
			width: fit-content
			@media screen and (max-width: 426px)
				display: flex
				flex-direction: column
				align-items: center
				width: 100%
				text-align: center
			& .sectionTitle
				font-weight: normal
				color: #ff00c0
				padding-bottom: 1.5rem
				margin: 0 0 1.5rem
				border-bottom: 1px solid #ff00c0
			& .sectionDescription
				color: #fff
				font-size: 1.6rem
				margin: 0 0 1.5rem
			& .btnAbout
				display: block
				width: 100%
				background: #ff00c0
				color: #fff
				padding: 0.5rem 2rem
				border: 1px solid transparent
				margin-bottom: 1rem
				cursor: pointer
			& .btnShareLink
				display: block
				width: 100%
				background: #2c7802
				color: #fff
				padding: 0.5rem 2rem
				border: 1px solid transparent
				cursor: pointer
				transition: all 0.5s
				&:hover
					background: #579933
			& .dynamicSrcBar
				display: flex
				height: 36px
				& input
					display: block
					width: 100%
					border: 1px solid #333
					background: #333
					color: #fff
				& .btnModalWindow
					display: block
					border: 1px solid transparent
					color: #ffffff
					background-color: #2c7802
					padding: 0 0.25rem
					cursor: pointer
					transition: all 0.5s
					&:hover
						background: #579933

	.tooth_styles_view
		background: #111111
		padding-bottom: 2rem
		min-height: 50vh
		& .tooth_styles_view-goBack
			margin: 0.5rem auto
			max-width: 1024px
			width: 100%
			& a
				display: inline-flex
				align-items: center
				padding: 0.5rem 0
				font-size: 18px
				color: #a0a0a0
				transition: all 0.5s
				&:hover
					color: #e0e0e0
		.tooth_styles_view_wrapper
			display: block
			max-width: 1024px
			width: 100%
			margin: 0 auto
			.toothStyle
				display: inline-block
				width: calc(100% / 5)
				border: 2px solid #111
				margin-bottom: -4px
				@media screen and (max-width: 769px)
					width: calc(100% / 4)
				@media screen and (max-width: 600px)
					width: calc(100% / 3)
				@media screen and (max-width: 426px)
					width: calc(100% / 2)
				&:hover
					border: 2px solid #444

				& img
					display: block
					width: 100%
					height: auto

#tooth_styles_about
	font-family: 'Roboto'
	letter-spacing: 0rem
	color: #ffffff

#tooth_styles_section_1
	padding: 2rem 1rem
	background-color: #25292b
	& .wrapper
		flex-direction: row
		@media screen and (max-width: 768px)
			flex-direction: column
		& .modelsDeisgn
			display: flex
			flex-direction: column
			align-items: center
			justify-content: center
			padding: 0 2rem
			& .modelsDeisgn-img
				display: block
				width: 100%
				max-width: 400px
				height: 140px
				object-fit: contain
			& .modelsDeisgn-about
				font-family: 'Ropa Sans Pro SC Regular'
				text-align: center
				font-size: 1.3rem
				margin: 1rem 0 0.5rem

#tooth_styles_section_2
	padding: 2rem 1rem
	background-color: #000000
	& .smileConstruct-title
		display: block
		margin: 0 auto
		width: 100%
		max-width: 890px
		font-family: 'Ropa Sans Pro SC Regular'
		text-align: center
		font-size: 1.2rem
		line-height: 2rem
	& .smileConstruct-preview
		display: flex
		flex-wrap: wrap
		justify-content: center
		& .smileConstruct-type
			display: flex
			flex-direction: column
			justify-content: flex-start
			align-items: center
			padding: 0 1.5rem
			width: calc(100% / 3)
			@media screen and (max-width: 1024px)
				width: auto
			& .smileConstruct-img
				display: block
				width: 100%
				height: auto
				object-fit: contain
			& .smileConstruct-about
				margin-top: 1rem
				font-family: 'Ropa Sans Pro SC Regular'
				font-size: 1.2rem
				text-align: center
				&.text-purple
					color: #ff00c0
				&.text-green
					color: #65cbe4

#tooth_styles_section_3
	padding: 2rem 1rem
	background-color: #25292b
	& .toothFashion3D-about
		line-height: 1.5rem
		font-weight: 300
		margin-bottom: 1.5rem
		& .toothFashion3D-about-accent
			color: #ff00c0
			font-size: 2rem
	& .toothFashion3D-title
		line-height: 1.5rem
		text-align: center
		font-weight: 500
		margin-bottom: 1.5rem
		& .toothFashion3D-title-accent
			color: #ff00c0
	& .toothFashion3D-demo
		display: flex
		flex-wrap: wrap
		justify-content: center
		& .toothFashion3D-demo-container
			display: block
			padding: 0 0.5rem
			max-width: 50%
			& .toothFashion3D-demo-container-img
				display: block
				width: 100%
				height: 100%
				object-fit: contain

#tooth_styles_section_4
	padding: 2rem 1rem
	background-color: #000000
	& .toothFashion2D-about-top
		line-height: 1.5rem
		font-weight: 300
		margin-bottom: 1.5rem
	& .toothFashion2D-about-img
		display: block
		width: 100%
		height: auto
		margin-bottom: 1.5rem
	& .toothFashion2D-about
		line-height: 1.5rem
		font-weight: 300
		margin-bottom: 1.5rem
		& .toothFashion2D-about-accent
			color: #00ecff
			font-size: 2rem
	& .toothFashion2D-title
		line-height: 1.5rem
		text-align: left
		font-weight: 300
		margin-bottom: 1.5rem
		& .toothFashion2D-title-accent
			color: #00ecff
	& .toothFashion2D-content
		display: flex
		flex-wrap: wrap
		justify-content: center
		& .toothFashion2D-content-container
			display: block
			padding: 0 0.5rem 0.5rem
			width: 50%
			@media screen and (max-width: 768px)
				width: 100%
			& .toothFashion2D-content-container-img
				display: block
				width: 100%
				height: 100%
				object-fit: contain

#tooth_styles_section_5
	padding: 2rem 1rem
	background-color: #25292b
	& .starterPack-title
		text-align: center
		font-weight: 300
		margin-bottom: 2rem
		& .starterPack-title-accent
			font-family: 'Ropa Sans Pro SC Regular'
			font-size: 2rem
			color: #ff67ab
	& .starterPack-container
		display: flex
		flex-wrap: wrap
		align-items: center
		justify-content: space-around
		@media screen and (max-width: 1024px)
			flex-direction: column
		& .starterPack-container-btn
			display: flex
			align-items: center
			justify-content: space-between
			margin: 0 1rem
			padding: 1rem
			width: 100%
			max-width: 430px
			font-family: 'Ropa Sans Pro SC Regular'
			font-size: 1.3rem
			color: #ffffff
			transition: all 0.3s
			@media screen and (max-width: 1024px)
				margin-bottom: 1rem
				&:last-child
					margin-bottom: 0
			&.btn-green
				background-color: #13b7c4
				&:hover
					background-color: #1099a4
			&.btn-purple
				background-color: #de3983
				&:hover
					background-color: #c33273
			& .starterPack-container-btn-arrow
				display: block
				width: auto
				height: 100%
				object-fit: contain
			& .starterPack-container-btn-text
				display: block
				width: 100%
				text-align: center
				line-height: 1.8rem

#tooth_styles_section_6
	padding: 2rem 1rem 4rem
	background-color: #000000
	& .catBack-title
		color: #ed1e6b
		font-size: 2rem
		margin-bottom: 1.5rem
	& .catBack-about
		display: block
		line-height: 1.5rem
		font-weight: 300
		margin-bottom: 1.5rem
		& .catBack-about-accent
			color: #ed1e6b
	& .catBack-steps
		display: flex
		flex-wrap: nowrap
		justify-content: center
		& .catBack-steps-item
			display: block
			padding: 0 0.5rem
			& img
				width: 100%
				height: 100%
				object-fit: contain
	& .catBack-scheme
		display: flex
		flex-wrap: nowrap
		justify-content: center
		& .catBack-scheme-pointer
			height: auto
			object-fit: contain
			padding: 0 0.9rem
			box-sizing: border-box
			width: 6.5%
		& .catBack-scheme-item
			display: flex
			flex-direction: column
			justify-content: center
			box-sizing: border-box
			padding: 0 1.5rem
			& .catBack-scheme-model
				height: 100%
				max-height: 5.5rem
				justify-content: center
				margin-bottom: 1.5rem
				& img
					width: 100%
					height: 100%
					object-fit: contain
			& .catBack-scheme-tooth
				height: 100%
				max-height: 15rem
				justify-content: center
				& img
					width: 100%
					height: 100%
					object-fit: contain

#tooth_styles_section_7
	padding: 2rem 1rem 4rem
	background-color: #000000
	& .demoVideoContent
		display: flex
		flex-direction: column
		align-content: center
		justify-content: center
		& .demoVideoContent-container
			display: block
			box-sizing: borderbox
			& .demoVideoContent-container-title
				font-size: 1.8rem
				font-weight: 700
				text-align: center
				padding: 2.5rem 1rem
			& .demoVideoContent-container-content
				display: block
				position: relative
				padding-bottom: 56.20% //16:9
				padding-top: 25px
				height: 0
				border: 1px solid transparent
				& iframe
					position: absolute
					top: 0
					left: 0
					width: 100%
					height: 100%
</style>
